import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios';
import { getCookie } from '../services/cookies';
import { uuid } from '../services/uuid';
import { webApiKey } from '../const/headers';

class Interceptors {
  setup() {
    axios.interceptors.request.use(this.requestInterceptor, this.handleError);
    axios.interceptors.response.use(this.responseInterceptor, this.handleError);
  }

  responseInterceptor = <T>(response: AxiosResponse<T>): AxiosResponse<T> => {
    return response;
  };

  requestInterceptor = async (
    request: AxiosRequestConfig
  ): Promise<AxiosRequestConfig> => {
    const token = getCookie('token');

    if (token) {
      request.headers.common['Authorization'] = `Bearer ${token}`;
      request.headers.common['Teal-Web-Api-Key'] = webApiKey;
      request.headers.common['teal-command-id'] = uuid();
    }

    return request;
  };

  handleError = async (error: AxiosError) => {
    throw error;
  };
}

export const interceptors = new Interceptors();
