/**
 * When building window is not rendered so need
 * to wrap window functions in this if block so
 * gatsby can build
 */

export const getCookie = (name: string): string | undefined => {
  const cookie = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
  if (cookie) {
    return cookie[2];
  }
};

export const setCookie = (name: string, value: string): void => {
  if (typeof window !== 'undefined' && window.document) {
    window.document.cookie = name + '=' + value;
  }
};

export const deleteCookie = (name: string): void => {
  if (typeof window !== 'undefined' && window.document) {
    window.document.cookie = `${name}=; Max-Age=-99999999;`;
  }
};
