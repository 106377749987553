import React, { useEffect } from 'react';
import styled from 'styled-components';
import { GlobalStyles } from '../../../styles/globalStyles';
import { colors } from '../../../styles/theme';
import { getToken } from '../../../services/documentHelpers';
import { setCookie } from '../../../services/cookies';
import { interceptors } from '../../../api/interceptors';
import { navigate } from 'gatsby';
import { paths } from '../../../const/paths';

const Wrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background-color: ${colors.white};
  transform: translate(-50%, -50%);
`;

const AuthenticateWeb: React.FunctionComponent = () => {
  useEffect(() => {
    setTokenToCookie();
  }, []);

  const setTokenToCookie = () => {
    const token = getToken();
    setCookie('token', token);
    interceptors.setup();
    navigate(paths.card);
  };

  return (
    <Wrapper>
      <GlobalStyles />
    </Wrapper>
  );
};

export default AuthenticateWeb;
